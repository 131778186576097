// import _ from 'lodash';
// window._ = _;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

window.post = function (url, params, abortSignal) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {signal: abortSignal?.signal})
            .then(res => {
                const detail = res.data
                if (typeof detail === 'object' && detail.hasOwnProperty('type')) {
                    toast(detail)
                }

                resolve(res.data)
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    return;
                }

                const detail = err.response.data
                if (typeof detail === 'object' && detail.hasOwnProperty('type')) {
                    toast(detail)
                }

                reject(detail)
            });
    })
}

window.toast = function (toast) {
    window.dispatchEvent(new CustomEvent('toast', {detail: toast}))
}

import slugify from "slugify";
window.slugify = slugify;
window.slugify.extend({
    'υ': 'u',
    'ύ': 'u',
    'Υ': 'u',
    'Ύ': 'U',
    'θ': 'th',
    'Θ': 'TH',
    'ξ': 'ks',
    'Ξ': 'KS',
    'η': 'i',
    'β': 'v',
    '&': ''
});

window.slugifyLower = function (string) {
    return slugify(string, { lower: true }).replace(/[^a-z\d\-]/g, '')
}
